<template>
    <v-sheet class="mx-3">
        <!--
        <v-toolbar color="primary" dark extended dense>
        -->
        <v-toolbar color="primary" dark>
            <v-toolbar-title>
                Cirugías
            </v-toolbar-title>
            <v-spacer/>

            <v-btn icon @click="list()">
                <v-icon>
                    mdi-autorenew
                </v-icon>
            </v-btn>

            <v-btn icon @click="ciruModal=true" v-if="$store.getters['security/datosUsuario'].roles.includes('ROLE_CIRUGIA_ENF')">
                <v-icon>
                    mdi-plus
                </v-icon>

            </v-btn>

            <!--
            <template v-slot:extension>
                {{moment(fechaArr[0], 'DD/MM/YYYY').format('ddd MMM DD \'YY')}} -> {{moment(fechaArr[1], 'DD/MM/YYYY').format('ddd MMM DD \'YY')}}
                <v-spacer/>
            </template>
            -->

        </v-toolbar>
        <v-row no-gutters>
            <v-col sm="4" md="3" cols="12">
                <v-sheet class="pa-2 mx-1" elevation="2">
                    <v-date-picker
                        class="my-2"
                        v-model="fechas"
                        range
                        full-width
                        no-title
                        locale="es-mx"
                        scrollable
                    />
                    <v-select
                        label="Estado"
                        v-model="datosBusqueda.status"
                        rounded
                        filled
                        dense
                        clearable
                        hide-details
                        :items="[
                            {value:1, text:'Solicitadas'},
                            {value:2, text:'Agendada'},
                            {value:3, text:'En sala'},
                            {value:5, text:'Salió'},
                            {value:90, text:'Reagendada'},
                            {value:99, text:'Cancelada'},
                        ]"
                    />
                    <v-text-field
                        class="mt-3"
                        label="Folio"
                        v-model="datosBusqueda.folio"
                        rounded
                        filled
                        dense
                        clearable
                        hide-details
                    ></v-text-field>
                    <v-text-field
                        class="mt-3"
                        label="Paciente"
                        v-model="datosBusqueda.paciente"
                        type="number"
                        rounded
                        filled
                        dense
                        clearable
                        hide-details
                    ></v-text-field>
                </v-sheet>
            </v-col>
            <v-col sm="8" md="9" cols="12">

                <v-data-table
                    elevation="2"
                    :headers="headers"
                    :items="datos"
                    disable-sort
                    :footer-props="{
                        'items-per-page-options': [10, 20, 30, 40, 50]
                    }"
                    item-key="id"
                    :loading="loadingDatosTodos"

                    :server-items-length='totalRegistros'
                    :options.sync="optionsTable"
                >
                    <template v-slot:[`item.urgencia`]="{ item }">
                        <v-icon color="red" v-if="item.urgencia">mdi-flag</v-icon>
                    </template>

                    <template v-slot:[`item.sala`]="{ item }">
                        <div v-if="item.fechaAsignada">Sala: {{item.sala}}</div>
                        <div>{{item.fechaAsignada}}</div>
                        <div v-if="!item.fechaAsignada">Solicitada para: {{item.fechaSolicitada}}</div>
                        <div v-if="!item.fechaAsignada && $store.getters['security/datosUsuario'].roles.includes('ROLE_CIRUGIA_ENF')">
                            <v-btn color="green" text small outlined @click="abreConfirmaCirugia(item)">Confirmar</v-btn></div>
                    </template>

                    <template v-slot:[`item.pacienteNombre`]="{ item }">
                        <div>{{item.paciente}}</div>
                        <div class="font-weight-bold">{{item.pacienteNombre}}</div>
                        <div class="text-caption">Proc: {{item.procedimiento}}</div>
                        <div class="text-caption">Diag: {{item.padecimiento}}</div>
                    </template>

                    <template v-slot:[`item.staff`]="{ item }">
                        <div>Médico: {{getIntegrantes(item.staff, 1).staff}}</div>
                        <div>Anestesio: {{getIntegrantes(item.staff, 2).staff}}</div>
                    </template>

                    <template v-slot:[`item.status`]="{ item }">
                        <v-chip :color="getStatus(item.status).color" label @click="muestraAlarma(item)">
                            <div class="white--text">{{getStatus(item.status).text}}</div>
                        </v-chip>
                    </template>

                    <template v-slot:[`item.view`]="{ item }">
                        <div>
                            <v-btn
                                @click="cargaPaquete(item)"
                                v-if="$store.getters['security/datosUsuario'].roles.includes('ROLE_CIRUGIA_MED')"
                                small
                                icon
                            >
                                <v-icon>mdi-note-check</v-icon>
                        </v-btn>

                            <v-btn
                                @click="cargaDatos(item)"
                                small
                                icon
                            >
                                <v-icon>mdi-magnify</v-icon>
                            </v-btn>
                        </div>
                    </template>

            </v-data-table>

            <ciru-nueva
                :dialogModal.sync="ciruModal"
                @guardado="list()"
            ></ciru-nueva>

            <ciru-paquete
                :dialogModal.sync="ciruPaquete"
                :cirugia="estaCirugia"
            ></ciru-paquete>
            <ciru-confirma
                :dialogModal.sync="ciruConfirma"
                :cirugia="estaCirugia"
                @guardado="list()"
            ></ciru-confirma>

            </v-col>
        </v-row>
    </v-sheet>
</template>

<script>
export default {
    props:{
        estosDatos:Object,
    },
    components:{
        'ciru-paquete':()=>import('@/views/quirofano/QuirofanoPaqueteoperes'),
        'ciru-nueva':()=>import('@/views/quirofano/QuirofanoNuevaCirugia'),
        'ciru-confirma':()=>import('@/views/quirofano/QuirofanoConfirma')
    },
    mounted(){

        if (this.fechas.length==0){
            //console.log('cambiando fechas')
            this.fechaArr[0] = this.estaFecha
            this.fechaArr[1] = this.estaFecha
        }
    },
    data:()=>({
        //fechas:[],
        //fechaArr:[],

        datos:[],
        headers:[
            {text:'', value:'urgencia', align:'center'},
            {text:'Sala', value:'sala', width:'100px', align:'center'},
            {text:'Paciente', value:'pacienteNombre'},
            {text:'Médico', value:'staff'},
            {text:'Folio', value:'folio'},
            {text:'Estado', value:'status', width:'10%', align:'center'},
            {text:'', value:'view', width:'90px', align:'center'},
        ],
        loadingDatosTodos:false,
        totalRegistros:0,
        //optionsTable:{},
        params:{},

        //datosBusqueda:{},

        ciruPaquete:false,
        estaCirugia:{},

        ciruModal:false,
        ciruConfirma:false,
    }),

    methods:{
        abreConfirmaCirugia(item){
            this.estaCirugia = item
            this.ciruConfirma = true
        },
        cargaDatos(item){
            //console.log(item)
            //this.$emit("update:estosDatos", item)
            //this.estaCirugia = item
            //this.ciruPaquete = true
            if (item.folio){
                console.log('Cargando POR FOLIO')
                this.$router.push({path:`/quirofano/${item.folio}`})
            } else {
                console.log('Cargando POR ID')
                this.$router.push({path:`/quirofano/${item.id}`})
            }
        },
        cargaPaquete(item){
            this.estaCirugia = item
            this.ciruPaquete = true
        },


        async list(){

            this.params = {}
            this.params = {...this.datosBusqueda}

            if (
                this.$store.getters['security/datosUsuario'].roles.includes('ROLE_CIRUGIA_MED') &&
                !this.$store.getters['security/datosUsuario'].roles.includes('ROLE_ADMIN')
            ){
                this.params.username = this.$store.getters['security/datosUsuario'].username
            }

            if (this.params.status >1 || !this.params.paciente){
                this.params.fechaADe=this.fechaArr[0]
                this.params.fechaAA=this.fechaArr[1]
            }

            if (this.params.status == 1){
                this.params.paciente = null
            }

            if (this.params.status){
                this.params.status = parseInt(this.params.status)
            }


            this.params.start=(this.optionsTable.page-1 )*this.optionsTable.itemsPerPage
            this.params.limit=this.optionsTable.itemsPerPage
            //let params={...this.optionsTable}
            this.loadingDatosTodos=true
            try {
                let req = await this.$http({
                    url: '/cirugia/listCirugia',
                    method:'GET',
                    params: this.params,
                })
                this.loadingDatosTodos=false
                this.datos = req.data.root
                this.totalRegistros = req.data.total
            } catch (err) {
                this.loadingDatosTodos=false
                this.$store.dispatch('security/revisaError',err)
            }
        },
        getIntegrantes(datos, tipo){
            //1: Médico, 2: Anestesiólogo, 3: Ayudante, 4: Interumentista, 5: Circulante, 99: otros 
            let persona = datos.find(el=>el.tipoStaff==tipo)
            return persona?persona:{staff:'?'}
        },
        getStatus(tipo){
            let sta = {color:'black', text:'?'}
            switch (tipo){
                // 1: solicitada, 2: Agendada, 3: En sala, 4; En recuperación, 5: Salió, 90: Reagendada, 99: cancelada,
                case 1:
                    sta.text="Solicitada",
                    sta.color="purple"
                    break
                case 2:
                    sta.text="Agendada",
                    sta.color="blue"
                    break
                case 3:
                    sta.text="En sala",
                    sta.color="orange"
                    break
                case 4:
                    sta.text="En recuperación",
                    sta.color="orange"
                    break
                case 5:
                    sta.text="Salió",
                    sta.color="green"
                    break
                case 90:
                    sta.text="Reagendada",
                    sta.color="red"
                    break
                case 99:
                    sta.text="Cancelada",
                    sta.color="black"
                    break
            }
            return sta
        },
        muestraAlarma(itm){
            //console.log(itm)
            let titulo
            let texto

            if (itm.status == 2){
                titulo = "No procesada"
                texto = 'Aún no se recibe este paciente'
            }
            if (itm.status == 3){
                titulo = "En sala"
                texto = 'Recibido '+itm.horaLlegada
            }
            if (itm.status == 5){
                titulo = "Terminada"
                texto = 'Salió el '+itm.horaSalida
            }
            if (itm.status == 90){
                titulo = "REAGENDADA"
                texto = itm.motivoReagenda
            }
            if (itm.status == 99){
                titulo = "CANCELADA"
                texto = itm.motivoCancela
            }

            this.$swal.fire({
                icon: 'info',
                title: titulo,
                text: texto,
            })
        }
    },

    computed:{
        datosBusqueda:{
            get(){
                return (this.$store.state.quirofano.datosBusqueda)
            },
            set(v){
                this.$store.commit('quirofano/ACTUALIZA_PARAMS', v)
            },
        },

        fechas:{
            get(){
                return (this.$store.state.quirofano.fechas)
            },
            set(v){
                this.$store.commit('quirofano/ACTUALIZA_FECHAS', v)
            },
        },

        fechaArr:{
            get(){
                return (this.$store.state.quirofano.fechaArr)
            },
            set(v){
                this.$store.commit('quirofano/ACTUALIZA_FECHAARR', v)
            },
        },
        optionsTable:{
            get(){
                return (this.$store.state.quirofano.optionsTableList)
            },
            set(v){
                this.$store.commit('quirofano/ACTUALIZA_OPTIONS_LIST', v)
            },
        },

        estaFecha(){
            return (this.moment(new Date()).format('DD/MM/YYYY'))
        },

        fechaDe(){
            return (this.getFecha(this.fechaArr[0]))
        },
        fechaA(){
            return (this.getFecha(this.fechaArr[1]))
        },
    },


    watch:{
        fechas(){
            //this.params.folio = null
            //console.log(v)
            let far=[]
            this.fechaArr = []
            if(this.fechas.length > 0){
                this.fechas.forEach(fecha => {
                    far.push(new Date(fecha+'T00:00:00'))
                })

                this.fechaArr.push(this.moment(new Date(Math.min.apply(null,far))).format('DD/MM/YYYY'))
                this.fechaArr.push(this.moment(new Date(Math.max.apply(null,far))).format('DD/MM/YYYY'))
            }
            this.list()

        },
        optionsTable: {
            handler () {
                this.list()
            },
            deep: true,
        },

        /*
        datosBusqueda: {
            handler () {
                this.list()
            },
            deep: true,
        },
        */
        'datosBusqueda.status'(){
            this.optionsTable.page = 1
            this.list()
        },
        'datosBusqueda.folio'(){
            this.optionsTable.page=1
            this .list()
        },
        'datosBusqueda.paciente'(v){
            if (v >= 10000001){
                this.optionsTable.page = 1
                this.list()
            }
            if (!v){
                this.optionsTable.page = 1
                this.list()
            }
        }
    },

}
</script>

<style>

</style>